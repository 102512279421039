import { Alert,Snackbar,Typography,Tooltip,InputAdornment, Stack,Container,useTheme,IconButton, Box,  Button, TextField} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'; // Icon for adding users
import RestartAltIcon from '@mui/icons-material/RestartAlt'; // Icon for resetting passwords
import SearchIcon from '@mui/icons-material/Search';

import React, { useRef, useState, useEffect,useCallback } from 'react';
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import CoPresentOutlinedIcon from '@mui/icons-material/CoPresentOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import Header from "../../components/Header";
import { fetchUsers, reset_password, } from "../../actions/auth"; // Assuming you'll create these functions
import FormDialog from "../form";
import { useSelector, useDispatch } from 'react-redux';

import { useNavigate } from 'react-router-dom'; // Import useNavigate
import LoadingOverlay from "../../components/LoadingOverlay";
const Team = () => {
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState('');

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState({ severity: "info", message: "" });
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate(); // Hook for navigation
  const user = useSelector(state => state.auth.user);
  const { group_names } = user || {};

  const role = group_names?.[0] || '';
  // Define a function to handle navigation to a student's exam records

  useEffect(() => {
    const loadUsers = async () => {
      setIsLoading(true);
      try {
        const data = await fetchUsers();
        setUsers(data);
      } catch (error) {
        handleUserActionFeedback(false, "Error fetching users.");
      } finally {
        setIsLoading(false);
      }
    };
    loadUsers();
  }, []);
  

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };
  const handleSearchChange = useCallback((event) => {
    setSearchText(event.target.value);
  }, []);
  
  const handleRowClick = useCallback((params) => {
    navigate(`/students/${params.id}/exams`);
  }, [navigate]);
  


  const handleUserActionFeedback = (success, message) => {
    setSnackbarContent({ severity: success ? "success" : "error", message });
    setSnackbarOpen(true);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const safeUsers = Array.isArray(users) ? users : [];

  const filteredUsers = safeUsers
    .filter((user) => (
        user.first_name.toLowerCase().includes(searchText.toLowerCase()) ||
        user.last_name.toLowerCase().includes(searchText.toLowerCase()) ||
        user.email.toLowerCase().includes(searchText.toLowerCase()) ||
        user.group_names[0].toLowerCase().includes(searchText.toLowerCase())
    ))
    .sort((a, b) => {
        // Compare roles to prioritize 'STUDENT'
        const roleA = a.group_names[0].toUpperCase();
        const roleB = b.group_names[0].toUpperCase();

        if (roleA === 'STUDENT' && roleB !== 'STUDENT') {
            return -1; // a is a student, but b is not, a should come first
        } else if (roleB === 'STUDENT' && roleA !== 'STUDENT') {
            return 1; // b is a student, but a is not, b should come first
        } else {
            // If both are students or neither are, sort by another attribute (e.g., last name)
            return a.last_name.localeCompare(b.last_name);
        }
    });
    const totalMembers = filteredUsers.length;
    const totalStudents = filteredUsers.filter(user => user.group_names[0].toLowerCase() === 'student').length;
    

    const handleResetPassword = useCallback(() => {
      // Your reset password logic
    
    // Assuming `users` state contains all user details including emails
    setIsLoading(true); // Start loading

    const emailsToReset = users.filter(user => selectedUsers.includes(user.id)).map(user => user.email);
  
    // Initialize counters to track completion and errors
    let completedRequests = 0;
    let errorOccurred = false;
    const totalRequests = emailsToReset.length;
  
    if (totalRequests === 0) {
      // No users selected, handle accordingly
      setIsLoading(false); // Stop loading if no users selected

      handleUserActionFeedback(false, "No users selected for password reset.");
      return;
    }
  
    emailsToReset.forEach(email => {
      // Dispatch the action with callbacks for success and error
      dispatch(reset_password(email, () => {
        completedRequests++;
        if (completedRequests === totalRequests) {
          if (!errorOccurred) {
            handleUserActionFeedback(true, "Password reset successful for selected users.");
            setSelectedUsers([]); // Clear selection
          }
          setIsLoading(false); // Stop loading
        }
      }, () => {
        errorOccurred = true; // Mark that an error occurred
        if (completedRequests === totalRequests) {
          handleUserActionFeedback(false, "An error occurred. Not all passwords may have been reset.");
          setIsLoading(false); // Stop loading
        }

      }));
    });
  }, [selectedUsers, dispatch, users]); // Add relevant dependencies
  


  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "first_name",
      headerName: "First name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "last_name",
      headerName: "Last name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "groups",
      headerName: "Access Level",
      flex: 1,
      renderCell: ({ row: { group_names } }) => {
        const role = group_names[0].toUpperCase();

        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              role === 'STUDENT'
                      ? colors.greenAccent[600] // More visible color for students
                      : colors.grey[500] // Less visible color for admins and teachers
          }
            borderRadius="4px"
          >
            {role === 'ADMIN' && <AdminPanelSettingsOutlinedIcon />
}
            {role === 'TEACHER' && <CoPresentOutlinedIcon />
}
            {role === 'STUDENT' && <SchoolOutlinedIcon />}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {role}
            </Typography>
          </Box>
        );
      },
      
      
    },
    
  ];


  return (
    <Container sx={{ margin: '20px auto', maxWidth: 'lg' }}>
      
      <Header title="MANAGE TEAM" subtitle={`Total Members: ${totalMembers}`} subtitle2={`- Students: ${totalStudents}`} page="manageTeam" />

      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
      <Tooltip 
  title={role !== 'admin' && role !== 'staff' ? "This action is available for admins only." : ""}
  disableHoverListener={role === 'admin' || role ==='staff'} // Disable tooltip when the user is an admin or staff
>
  <span> {/* Wrap the button in a span to allow tooltip functionality on disabled elements */}
    <Button
      variant="contained"
      color="primary"
      startIcon={<AddCircleOutlineIcon />}
      onClick={handleOpenDialog}
      sx={{ '&:focus': { outline: 'none' } }}
      disabled={role !== 'admin' && role !== 'staff'} // Corrected: Disable if not admin or staff
    >
      Add User
    </Button>
  </span>
</Tooltip>

  <Tooltip 
  title={(!selectedUsers.length || isLoading || (role !== 'admin' && role !== 'staff')) ? "This action is available for admins or staff only." : ""}
  disableHoverListener={selectedUsers.length > 0 && !isLoading && (role === 'admin' || role === 'staff')} // Disable tooltip when user is admin or staff and conditions are met
>

    <span> {/* Wrap the button in a span to allow tooltip functionality on disabled elements */}
      <Button
        variant="outlined"
        color="primary"
        startIcon={!isLoading && <RestartAltIcon />}
        onClick={handleResetPassword}
        disabled={!selectedUsers.length || isLoading || (role !== 'admin' && role !== 'staff')} // Additionally disable if not admin
        sx={{ '&:focus': { outline: 'none' } }}
      >
        {isLoading ? <LoadingOverlay/> : "Reset Password"}
      </Button>
    </span>
  </Tooltip>
</Stack>
      <Box sx={{
        height: '75vh', "& .MuiDataGrid-root": { border: "none" }, 
      }}>
              <Box sx={{ mb: 2 }} >
            <TextField
                fullWidth
                label="Search by Name or Email"
                variant="outlined"
                value={searchText}
                onChange={handleSearchChange}
                
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end" sx={{
                          '&:focus': {
                            outline: 'none',
                          }       }}>
                            <IconButton sx={{
                  '&:focus': {
                    outline: 'none',
                  }       }}>
                                <SearchIcon sx={{
                  '&:focus': {
                    outline: 'none',
                  }       }}/>
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                sx={{
                  '&:focus': {
                    outline: 'none',
                  }       }}
         
            />
        </Box>
        <DataGrid
  checkboxSelection
  rows={filteredUsers}
  columns={columns}
  onRowSelectionModelChange={(newSelection) => {
    setSelectedUsers(newSelection); // Update selected users
  }}
  selectionModel={selectedUsers} // Bind the selected user IDs
  onRowClick={handleRowClick}
  disableSelectionOnClick
  sx={{
    height: '75vh',
    '& .MuiDataGrid-root': { border: "none" },
    '& .MuiDataGrid-row:hover': {
      cursor: 'pointer',
    },
  }}
/>

      </Box>

      <FormDialog open={openDialog} handleClose={handleCloseDialog} onActionFeedback={handleUserActionFeedback} user={role} />
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarContent.severity} sx={{ width: '100%' }}>
          {snackbarContent.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Team;
