import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Typography, Button, Grid, Box, Divider, useTheme } from '@mui/material';

const MyPastExams = ({ exam }) => {
    const theme = useTheme(); // useTheme hook to access the default theme for styling
    const isPast = useMemo(() => exam.status === 'C', [exam.status]);
    const navigate = useNavigate();

    const formatDateTime = (date) => {
        return date.toLocaleString('en-US', {
            month: 'long', // Full month name
            day: 'numeric', // Day of the month
            year: 'numeric', // Four digit year
            hour12: true // 12-hour format with AM/PM
        });
    };

    const handleViewDetails = useCallback(() => {
        navigate(`/exam-details/${exam.id}`);
    }, [exam, navigate]);

    const ScoreCard = ({ exam }) => {
        if (exam.status === 'C') {
            const isFullExam = exam.exam_schedule.schedule_type === 'Full';
            const totalMaxScore = isFullExam ? 1600 : 800;
            const scoreCaption = `Score Range: 200 to ${totalMaxScore}`;
            const englishUnavailable = exam.exam_schedule.schedule_type === 'Math';
            const mathUnavailable = exam.exam_schedule.schedule_type === 'English';

            return (
                <Box sx={{
                    p: 2,
                    backgroundColor: '#FFFFFF',
                    borderRadius: '16px',
                    boxShadow: '0 8px 24px rgba(0, 0, 0, 0.12)',
                    textAlign: 'center',
                }}>
                    <Typography variant="subtitle1" color={theme.palette.primary.main} sx={{ mb: 2, fontWeight: 'bold' }}>
                        {formatDateTime(new Date(exam.completed_exam_time))}
                    </Typography>
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                        {exam.exam_schedule.exam_name} - {exam.exam_schedule.schedule_type}
                    </Typography>
                    <Divider sx={{ my: 3 }} />
                    <Typography variant="h6" gutterBottom>
                        Your Total Score
                    </Typography>
                    <Typography variant="h2" gutterBottom sx={{ color: theme.palette.primary.dark, fontWeight: 'bold' }}>
                        {isFullExam ? exam.eng_score + exam.math_score: 'N/A'}
                    </Typography>

                    <Typography variant="caption">{scoreCaption}</Typography>
                    <Box sx={{ mt: 1 }}>
  <Typography variant="caption">
    Auto Transition: {exam.exam_schedule.time_limit ? 'True' : 'False'}
  </Typography>
</Box>
                    <Divider sx={{ my: 3 }} />
                    <Box display="flex" justifyContent="space-between" px={3}>
                        <Box textAlign="left">
                            <Typography variant="subtitle1" gutterBottom>Reading and Writing Score</Typography>
                            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                                {englishUnavailable ? 'N/A' : exam.eng_score}
                            </Typography>
                            { !englishUnavailable && <Typography variant="caption">200 to 800</Typography> }
                        </Box>
                        <Box textAlign="right">
                            <Typography variant="subtitle1" gutterBottom>Math Score</Typography>
                            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                                {mathUnavailable ? 'N/A' : exam.math_score}
                            </Typography>
                            { !mathUnavailable && <Typography variant="caption">200 to 800</Typography> }
                        </Box>
                    </Box>
                    <Box mt={4}>
                        <Button variant="contained" color="primary" fullWidth  onClick={handleViewDetails}>
                            Score Details
                        </Button>
                    </Box>
                </Box>
            );
        }
        return null;
    };


    return (
        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Card sx={{ maxWidth: 345, width: '100%' }}>
                {isPast && <ScoreCard exam={exam} />}
            </Card>
        </Grid>
    );
};

export default React.memo(MyPastExams);
