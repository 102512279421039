import React, { useState, useEffect } from 'react';
import { Card, CardContent,Switch, Typography, IconButton, CircularProgress, Grid, CardActions, Chip, Dialog, Box, DialogTitle, DialogContent, DialogActions, Button, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import EditCalendarOutlinedIcon from '@mui/icons-material/EditCalendarOutlined';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { scheduleExam,regradeExam } from '../actions/auth';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'; // or '@mui/lab/AdapterDateFns' based on your version
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import CoPresentOutlinedIcon from '@mui/icons-material/CoPresentOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import { useTheme } from "@mui/material";
import { tokens } from "../theme"; 
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert'; // Might need to import from '@mui/lab/Alert' based on your MUI version
import { VisibilityOutlined } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import OutlinedInput from '@mui/material/OutlinedInput';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';



import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined';
const ManageMyExams = React.memo(({ exam ,users}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();
    const [openScheduleDialog, setOpenScheduleDialog] = useState(false);
    const [startTime, setStartTime] = useState(new Date());
    const [endTime, setEndTime] = useState(new Date());
    const [autoTransition, setAutoTransition] = useState(true); // default true for auto transition
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
    const [scheduleType, setScheduleType] = useState('Full'); // State for schedule type
    const [isScheduling, setIsScheduling] = useState(false);
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const [isRegrading, setIsRegrading] = useState(false);
    const [openRegradeDialog, setOpenRegradeDialog] = useState(false);

    // const [selectedUsers, setSelectedUsers] = useState([]);
    const [searchText, setSearchText] = useState('');

    const handleViewExamDetails = () => {
        navigate(`/viewExamDetails/${exam.id}`);
    };

    const handleOpenScheduleDialog = () => {
        const now = new Date();
        setStartTime(now);
        setEndTime(new Date(now.getTime() + 60 * 60 * 2*24000)); // 1 hour later
        setOpenScheduleDialog(true);
    };

    const formatDateTime = (date) => {
        return date.toLocaleString('en-US', {
          month: 'short', // Abbreviated month name
          day: 'numeric', // Day of the month
          year: 'numeric', // Four digit year
          hour: 'numeric', // Hour (1-12)
          minute: 'numeric', // Minute (0-59)
          hour12: true // 12-hour format with AM/PM
        });
      };
      
      const handleRegrade = async () => {
        setIsRegrading(true);
        setOpenRegradeDialog(false);

        try {
            const result = await regradeExam(exam.id);
            if (result) {
                setSnackbar({ open: true, message: 'Exam regraded successfully.', severity: 'success' });
            } else {
                setSnackbar({ open: true, message: 'Failed to regrade exam.', severity: 'error' });
            }
        } catch (error) {
            setSnackbar({ open: true, message: `Error: ${error.message}`, severity: 'error' });
        }
        setIsRegrading(false);
    };
    const handleOpenRegradeDialog = () => {
        setOpenRegradeDialog(true);
    };
    const handleAutoTransitionChange = (event) => {
        setAutoTransition(event.target.checked);
      };
      

    const handleCloseRegradeDialog = () => {
        setOpenRegradeDialog(false);
    };
    const formatCreatedAt = (createdAt) => {
      return createdAt ? formatDateTime(new Date(createdAt)) : "Unknown";
  };

    const handleCloseScheduleDialog = () => {
        setOpenScheduleDialog(false);
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };
    
    const handleSchedule = async () => {
        setIsScheduling(true);
    
        const isoStartTime = startTime.toISOString();
        const isoEndTime = endTime.toISOString();
    
        // Check if the end time is after the start time
        if (isoEndTime <= isoStartTime) {
            setSnackbar({ open: true, message: "End time must be after start time.", severity: 'error' });
            setIsScheduling(false);
            return;
        }
    
        // Check if at least one student is selected
        if (selectedUserIds.length === 0) {
            setSnackbar({ open: true, message: "Please select at least one student.", severity: 'error' });
            setIsScheduling(false);
            return;
        }
    
        try {
            const result = await scheduleExam(exam.id, isoStartTime, isoEndTime, selectedUserIds, scheduleType,autoTransition);
    
            if (result) {
                setSnackbar({ open: true, message: 'Exam scheduled successfully.', severity: 'success' });
                setSelectedUserIds([]);  // Clear selected user IDs after success

            } else {
                setSnackbar({ open: true, message: 'Failed to schedule exam.', severity: 'error' });
            }
        } catch (error) {
            setSnackbar({ open: true, message: `Error: ${error.message}`, severity: 'error' });
        }
    
        handleCloseScheduleDialog();
        setIsScheduling(false);
    };
    

    // const roundTimeToNearestMinute = (date) => {
    //     return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), 0, 0);
    // };
    
    // const handleStartTimeChange = (newTime) => {
    //     if (newTime > new Date()) {
    //         const roundedStartTime = roundTimeToNearestMinute(newTime);
    //         setStartTime(roundedStartTime);
    //         setEndTime(new Date(roundedStartTime.getTime() + 60 * 60 * 5000)); // Update end time to be 4 hour after new start time
    //     }
    // };
    
    // const handleEndTimeChange = (newTime) => {
    //     if (newTime > startTime) {
    //         const roundedEndTime = roundTimeToNearestMinute(newTime);
    //         setEndTime(roundedEndTime);
    //     }
    // };

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    const safeUsers = Array.isArray(users) ? users : [];

    const matchedUsers = safeUsers.filter((user) =>
    user.first_name.toLowerCase().includes(searchText.toLowerCase()) ||
    user.last_name.toLowerCase().includes(searchText.toLowerCase()) ||
    user.email.toLowerCase().includes(searchText.toLowerCase()) ||
    user.group_names[0].toLowerCase().includes(searchText.toLowerCase())
);

// Ensure selected users are included even if they don't match the search text
const filteredUsers = [
    ...matchedUsers,
    ...safeUsers.filter(
        (user) => selectedUserIds.includes(user.id) && 
        !matchedUsers.some((matchedUser) => matchedUser.id === user.id)
    )
].sort((a, b) => {
    const roleA = a.group_names[0].toUpperCase();
    const roleB = b.group_names[0].toUpperCase();

    if (roleA === 'STUDENT' && roleB !== 'STUDENT') {
        return -1;
    } else if (roleB === 'STUDENT' && roleA !== 'STUDENT') {
        return 1;
    } else {
        return a.last_name.localeCompare(b.last_name);
    }
});

        const usersWithSelectedAtTop = [
            ...filteredUsers.filter((user) => selectedUserIds.includes(user.id)), // ['Bob'] (if Bob was already selected)
            ...filteredUsers.filter((user) => !selectedUserIds.includes(user.id)) // ['Jimmy'] (all users matching the search term "Jimmy")
          ];
        //   console.log('usersWithSelectedAtTop',usersWithSelectedAtTop)
    const userColumns = [
        { field: 'id', headerName: 'ID', width: 90 },
        { field: 'first_name', headerName: 'First Name', width: 150 },
        { field: 'last_name', headerName: 'Last Name', width: 150 },
        { field: 'email', headerName: 'Email', width: 200 },
        {
            field: 'group_names',
            headerName: 'Role',
            width: 200,
            renderCell: ({ row }) => {
                const role = row.group_names[0].toUpperCase();
                return (
                    <Box
                        width="60%"
                        m="0 auto"
                        p="5px"
                        display="flex"
                        justifyContent="center"
                        backgroundColor={
                            role === 'STUDENT'
                                ? colors.greenAccent[600]
                                : colors.grey[500]
                        }
                        borderRadius="4px"
                    >
                        {role === 'ADMIN' && <AdminPanelSettingsOutlinedIcon />}
                        {role === 'TEACHER' && <CoPresentOutlinedIcon />}
                        {role === 'STUDENT' && <SchoolOutlinedIcon />}
                        <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
                            {role}
                        </Typography>
                    </Box>
                );
            },
        },
    ];

    return (
        <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ maxWidth: 345, width: '100%' }}>
                <CardContent>
                    <Typography variant="h5" gutterBottom>
                        {exam.name}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                        Exam Category: {exam.exam_type}
                    </Typography>
                    <Chip
                        label={exam.is_published ? 'Published' : 'Unpublished'}
                        color={exam.is_published ? 'success' : 'error'}
                        size="small"
                        sx={{ marginBottom: '8px' }}
                    />
                    <Typography variant="subtitle1" color="textSecondary">
                        Total Modules: {exam.modules_count}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                        Duration: {exam.duration}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                        Created By: {formatCreatedAt(exam.created_at)}
                    </Typography>
                </CardContent>
                <CardActions>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Box>
            <IconButton onClick={handleViewExamDetails} aria-label="edit exam details" color="primary" sx={{ '&:focus': { outline: 'none' } }}>
                <ModeEditOutlineOutlinedIcon />
            </IconButton>
            <IconButton
                onClick={handleOpenScheduleDialog}
                aria-label="schedule exam"
                color="primary"
                disabled={!exam.is_published}
                sx={{ '&:focus': { outline: 'none' } }}
            >
                <EditCalendarOutlinedIcon />
            </IconButton>
            <IconButton
                onClick={() => navigate(`/previewExam/${exam.id}`)}
                aria-label="preview exam"
                color="primary"
                sx={{ '&:focus': { outline: 'none' } }}
            >
                <VisibilityOutlined />
            </IconButton>
        </Box>
        
        <Button
            onClick={handleOpenRegradeDialog}
            startIcon={isRegrading ? <CircularProgress size={20} /> : <CloudSyncOutlinedIcon />}
            disabled={!exam.is_published || isRegrading}
            sx={{ '&:focus': { outline: 'none' } }}
        >
            {isRegrading ? 'Regrading...' : 'Regrade'}
        </Button>
    </Box>
</CardActions>


            </Card>
            <Dialog open={openRegradeDialog} onClose={handleCloseRegradeDialog} fullWidth maxWidth="sm">
    <DialogTitle>Confirm Regrade</DialogTitle>
    <DialogContent>
        <Typography>
            Are you sure you want to regrade the exam? This will regrade all completed exams for the selected exam and recalculate the scores based on the updated answer key.
        </Typography>
    </DialogContent>
    <DialogActions>
        <Button onClick={handleCloseRegradeDialog}>Cancel</Button>
        <Button
            onClick={handleRegrade}
            variant="contained"
            startIcon={isRegrading ? <CircularProgress size={20} /> : <CloudSyncOutlinedIcon />}
            disabled={isRegrading}
        >
            {isRegrading ? 'Regrading...' : 'Regrade'}
        </Button>
    </DialogActions>
</Dialog>

            <Dialog open={openScheduleDialog} onClose={handleCloseScheduleDialog} fullWidth maxWidth="md">
                <DialogTitle>Schedule Exam</DialogTitle>
                <DialogContent>
                    <Box sx={{ mb: 2 }}>
                        <TextField
                            fullWidth
                            label="Search by Name or Email"
                            variant="outlined"
                            value={searchText}
                            onChange={handleSearchChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <DataGrid
  rows={usersWithSelectedAtTop}
  columns={userColumns}
  checkboxSelection
  onRowSelectionModelChange={setSelectedUserIds} // Directly set selected IDs
  selectionModel={selectedUserIds} // Keep selection model synced
  pageSize={15}
  autoHeight
  sx={{ mb: 2 }}
/>

<LocalizationProvider dateAdapter={AdapterDateFns}>
  <Grid container spacing={2}>
    <Grid item xs={6}>
      <DateTimePicker
        label="Start Date and Time"
        value={startTime}
        onChange={setStartTime}
        minDateTime={new Date()}
        fullWidth
        helperText="Select the start time for the exam."
      />
    </Grid>
    <Grid item xs={6}>
      <DateTimePicker
        label="End Date and Time"
        value={endTime}
        onChange={setEndTime}
        minDateTime={startTime} // Ensures end time is after start time
        fullWidth
        helperText="Select the end time for the exam."
      />
    </Grid>
  </Grid>
</LocalizationProvider>
<Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
  <Typography>Auto Transition to Next Module:</Typography>
  <Switch checked={autoTransition} onChange={handleAutoTransitionChange} />
</Box>
<FormControl fullWidth sx={{ mt: 2 }}>
  <InputLabel>Schedule Type</InputLabel>
  <Select
    value={scheduleType}
    onChange={(e) => setScheduleType(e.target.value)}
    displayEmpty
  >
    <MenuItem value="Full">Full</MenuItem>
    <MenuItem value="English">English</MenuItem>
    <MenuItem value="Math">Math</MenuItem>
  </Select>
</FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseScheduleDialog}>Cancel</Button>
                    <Button
                        onClick={handleSchedule}
                        variant="contained"
                        color="primary"
                        disabled={isScheduling}
                    >
                        {isScheduling ? 'Scheduling...' : 'Schedule'}
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                message={snackbar.message}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Grid>
    );
});

export default ManageMyExams;
