
import br from './login.riv';
import React, {
  useRef,
  useState,

  useEffect,
} from 'react';

import {
  useRive,
  useStateMachineInput,
  Layout,
  Fit,
  Alignment,
  StateMachineInput,
} from 'rive-react';
import './LoginFormComponent.css';
import { login } from '../actions/auth';
import { connect } from 'react-redux';
import { useNavigate,useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { Button} from '@mui/material';

const STATE_MACHINE_NAME = 'Login Machine';
const LOGIN_TEXT = 'Login';

/**
 * Use case for a simple login experience that incorporates a Rive asset with a
 * state machine to coordinate user interaction with a form
 * @param riveProps
 */
 const LoginFormComponent = ({ login, isAuthenticated }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const location = useLocation();

  const { email, password } = formData;
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/'); 
    }
  }, [isAuthenticated, navigate]);

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { rive: riveInstance, RiveComponent } = useRive({
    src: br,
    stateMachines: STATE_MACHINE_NAME,
    autoplay: true,
    layout: new Layout({ fit: Fit.Cover, alignment: Alignment.Center }),
  });
  const [inputLookMultiplier, setInputLookMultiplier] = useState(0);
  // const [loginButtonText, setLoginButtonText] = useState(LOGIN_TEXT);
  const inputRef = useRef(null);

  const isCheckingInput: StateMachineInput | null = useStateMachineInput(
    riveInstance,
    STATE_MACHINE_NAME,
    'isChecking'
  );
  const numLookInput: StateMachineInput | null = useStateMachineInput(
    riveInstance,
    STATE_MACHINE_NAME,
    'numLook'
  );
  const trigSuccessInput: StateMachineInput | null = useStateMachineInput(
    riveInstance,
    STATE_MACHINE_NAME,
    'trigSuccess'
  );
  const trigFailInput: StateMachineInput | null = useStateMachineInput(
    riveInstance,
    STATE_MACHINE_NAME,
    'trigFail'
  );
  const isHandsUpInput: StateMachineInput | null = useStateMachineInput(
    riveInstance,
    STATE_MACHINE_NAME,
    'isHandsUp'
  );
  useEffect(() => {
    if (location.state?.resetEmailSent) {
      setSnackbarMessage('Password reset email sent successfully!');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
      // Clear the state so it doesn't show again on refresh
      navigate(location.pathname, { replace: true, state: {} });
    }
  
    if (location.state?.resetEmailConfirm) {
      setSnackbarMessage('Password changed successfully!');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
      // Clear the state so it doesn't show again on refresh
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location, navigate]); 

  // Divide the input width by the max value the state machine looks for in numLook.
  // This gets us a multiplier we can apply for each character typed in the input
  // to help Teddy track progress along the input line
  useEffect(() => {
    if (inputRef?.current && !inputLookMultiplier) {
      setInputLookMultiplier(
        (inputRef.current as HTMLInputElement).offsetWidth / 100
      );
    }
  }, [inputRef]);

  // As the user types in the username box, update the numLook value to let Teddy know
  // where to look to according to the state machine
  const onUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVal = e.target.value;
    setFormData({ ...formData, email: newVal }); 

    if (!isCheckingInput!.value) {
      isCheckingInput!.value = true;
    }
  
    const numChars = newVal.length;
    numLookInput!.value = numChars * inputLookMultiplier;
  };
  const handleReturnToReset = () => {
    navigate('/reset-password');
};

  const handleInputChange = e => {
    onChange(e);
  
    onUsernameChange(e);
  };

  // Start Teddy looking in the correct spot along the username input
  const onUsernameFocus = () => {
    isCheckingInput!.value = true;
    if (numLookInput!.value !== email.length * inputLookMultiplier) {
      numLookInput!.value = email.length * inputLookMultiplier;
    }
  };

  // When submitting, simulate password validation checking and trigger the appropriate input from the
  // state machine
  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
  
    try {
      await login(email, password);
      setTimeout(() => {
        // setLoginButtonText(LOGIN_TEXT);
        trigSuccessInput!.fire(); // Trigger success animation
        setLoading(false); // Stop loading
      }, 1500);
    } catch (error) {
      console.error('Login failed:', error); // Log the error
      
      // Check if error response exists and contains status code
      let errorMessage = 'Oops! Something went wrong on our end.';
      if (error.response) {
        switch (error.response.status) {
          case 400: // Djoser uses 400 for incorrect email or password
          case 401: // 401 is also commonly used for unauthorized (invalid credentials)
            errorMessage = 'Incorrect email or password.';
            break;
          case 403:
            errorMessage = 'You do not have permission to access this resource.';
            break;
          case 500:
            errorMessage = 'Server error. Please try again later.';
            break;
          default:
            errorMessage = 'An unexpected error occurred.';
        }
      }
      
      setTimeout(() => {
        // setLoginButtonText(LOGIN_TEXT);
        trigFailInput!.fire(); // Trigger fail animation
        setLoading(false); // Stop loading
        setError(errorMessage); // Set the appropriate error message
      }, 1500);
    }
  };

  
  
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setOpenSnackbar(false);
};

  return (
    <div className="login-form-component-root">
{loading && (
      <Box className="loading-overlay" display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    )}
      <div className="login-form-wrapper">
        <div className="rive-wrapper">
          <RiveComponent className="rive-container" />
        </div>
        <div className="form-container">

          <form onSubmit={onSubmit}>
            <label>
              <input
                type="email"
                className="form-username"
                name="email"
                placeholder="Email"
                value={email}
                onChange={handleInputChange} 
                onBlur={() => (isCheckingInput!.value = false)}
                onFocus={onUsernameFocus}

                required
              />
            </label>
            <label>
              <input
                type="password"
                className="form-pass"
                name="password"
                placeholder="Password"
                value={password}
                onFocus={() => (isHandsUpInput!.value = true)}
                onBlur={() => (isHandsUpInput!.value = false)}
                onChange={onChange} // Simplified onChange handler\
                minLength={6}
                required
              />
            </label>
            <div className="error-message-placeholder">
            {error && (
                  <p className="mt-2 text-sm text-red-600 dark:text-red-400">
                    {error}
                  </p>
                )}
</div>
<p className='mt-3'>
            </p>
            <Button
                            type="submit"
                            variant="contained"
                            sx={{
                                width: '100%',
                                padding: '10px 0', // Increase padding for higher height
                                marginBottom: '8px', // Add some margin below the button
                                backgroundColor: 'primary.main',
                                '&:hover': {
                                    backgroundColor: 'primary.dark',
                                },
                            }}
                        >
                            {LOGIN_TEXT}
                        </Button>          </form>
                        <Button
                        variant="text"
                        color="primary"
                        onClick={handleReturnToReset}
                        style={{ marginTop: '10px' }}
                        sx={{
                          '&:focus': {
                            outline: 'none',
                          }       }}
                    >
Reset Password
                    </Button>
        </div>
      </div>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
  <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
    {snackbarMessage}
  </Alert>
</Snackbar>
    </div>
  );
};
const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { login })(LoginFormComponent);