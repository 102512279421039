import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, Box, TextField, DialogActions, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, CircularProgress } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from 'react-redux';
import { signup, reset_password } from '../../actions/auth';

const FormDialog = ({ open, handleClose, onActionFeedback,user }) => {
  const dispatch = useDispatch();
  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      const signupData = {
        email: values.email,
        first_name: values.firstName,
        last_name: values.lastName,
        group_name: values.role,
      };

      // Try creating the user
      const signupResponse = await dispatch(signup(signupData));

      if (signupResponse) {
        // Reset password after signup success
        await dispatch(reset_password(values.email));
        onActionFeedback(true, "Account created successfully! Check your email for password reset.");
        handleClose(); // Close the dialog after submission
      } else {
        onActionFeedback(false, "Failed to set the password");
      }
  } catch (error) {
    console.log('Error occurred:', error.response.status);

    // Check if the error has a response and status
    if (error.response) {
      const status = error.response.status;
      const errorData = error.response.data;

      // Handle based on status code
      switch (status) {
        case 400:
          // 400: Bad Request (Validation Errors)
          
          onActionFeedback(false, "An account with this email already exists. Please use a different email address.");
          
          break;

        case 401:
        case 403:
          // 403: Forbidden
          onActionFeedback(false, "You do not have permission to perform this action.");
          break;

        case 404:
          // 404: Not Found
          onActionFeedback(false, "The requested resource was not found.");
          break;

        case 500:
          // 500: Internal Server Error
          onActionFeedback(false, "A server error occurred. Please try again later.");
          break;

        default:
          // Handle any other status codes
          onActionFeedback(false, `An error occurred (Status: ${status}). Please try again.`);
          break;
      }
    } else {
      // No response from server (network error, timeout, etc.)
      onActionFeedback(false, "Network error. Please check your connection and try again.");
    }
  } finally {
    setSubmitting(false); // Stop the form submission state
  }

  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Create New User</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            role: "student",
          }}
          validationSchema={yup.object().shape({
            firstName: yup.string().required("Required"),
            lastName: yup.string().required("Required"),
            email: yup.string().email("Invalid email").required("Required"),
            role: yup.string().required("Required"),
          })}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box display="grid" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 1fr))">
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="First Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  name="firstName"
                  error={touched.firstName && Boolean(errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Last Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                  name="lastName"
                  error={touched.lastName && Boolean(errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="email"
                  label="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: "span 4" }}
                />
                <FormControl fullWidth sx={{ gridColumn: "span 4" }}>
                  <FormLabel>Role</FormLabel>
                  <RadioGroup
                    row
                    name="role"
                    value={values.role}
                    onChange={handleChange}
                  >
                    <FormControlLabel value="student" control={<Radio />} label="Student" />
                    <FormControlLabel value="teacher" control={<Radio />} label="Teacher" />
                    {user === 'admin' && <FormControlLabel value="staff" control={<Radio />} label="Staff" />}
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button type="submit" color="primary" variant="contained" disabled={isSubmitting}>
                    {isSubmitting ? <CircularProgress size={24} /> : "Create New User"}
                  </Button>
                </DialogActions>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default FormDialog;
