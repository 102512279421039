import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, Container, Link } from "@mui/material";
import Header from '../../components/Header';
import BugReportIcon from '@mui/icons-material/BugReport'; // If you're using MUI icons
import releaseNotesData from '../releaseNotes/notes.json';
import hello from './hello.json';
const Student = () => {
  const user = useSelector(state => state.auth.user);
  const [greeting, setGreeting] = useState(''); // State to store the greeting

  

  const [noteRefs, setNoteRefs] = useState([]);

  useEffect(() => {
    if (user?.group_names[0] === 'Student') {
      setNoteRefs(releaseNotesData.map(() => React.createRef()));
    }
  }, [user]);

  useEffect(() => {
    const getRandomGreeting = () => {
      const randomIndex = Math.floor(Math.random() * hello.length);
      const greeting = hello[randomIndex].hello;
      return `${greeting}, ${user?.first_name || 'Guest'}!`; // Localized greeting with the user's first name
    };
    setGreeting(getRandomGreeting());
  }, [user]); // Add user to the dependency array to re-run when user changes



  return (
    <Container sx={{ marginTop: '20px' }}>
      <Box sx={{ width: '100%', mt: 4 }}>
      <Header
  title={greeting}
  subtitle={(
    <>
      Check <a href="https://status.lightentest.com" target="_blank" rel="noopener noreferrer" style={{ color: '#2196F3', textDecoration: 'underline' }}>status.lightentest.com</a> for real-time updates on system performance and availability.
    </>
  )}
  page="dashboard"
/>


                {/* <Announcement announcements={announcements} /> */}



          <>
         <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <Link 
      href="https://docs.google.com/forms/d/e/1FAIpQLSfLssMK1KzgCfrLO9f-lccg_PZ08L2qnotZuWuYIRZrXCgoow/viewform?usp=sf_link" 
      target="_blank" 
      color="inherit" 
      sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        textDecoration: 'none', 
        padding: '10px 20px', 
        borderRadius: '8px', 
        border: '1px solid #ccc', 
        transition: 'background-color 0.3s', 
        '&:hover': { backgroundColor: '#f0f0f0' } 
      }}
    >
      <BugReportIcon sx={{ mr: 1 }} />
      Report a Bug
    </Link>

    {/* <Typography variant="h6" component="div" sx={{ textAlign: 'center', mt: 2 }}>

      Your feedback helps us improve!
    </Typography> */}
  </Box>
            <Box sx={{ flex: 1, pr: 2, mt: 4 }}>
              <Typography variant="h4" component="h1" gutterBottom>
                Recent Release Notes
              </Typography>
              {releaseNotesData.slice(0, 4).map((note, index) => (
                <Box key={index} ref={noteRefs[index]} sx={{ mb: 3 }}>
                  <Typography variant="h5" component="h2" color="primary" gutterBottom sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
                    {note.date}
                  </Typography>
                  {note.updates.map((update, idx) => (
                    <Typography key={idx} variant="body1" paragraph sx={{ fontSize: '1rem' }}>
                      - {update}
                    </Typography>
                  ))}
                </Box>
              ))}
              <Box sx={{ mt: 2 }}>
                <Link href="/release-notes" color="primary" underline="hover" sx={{ fontWeight: 'bold' }}>
                  View All Release Notes
                </Link>
              </Box>
            </Box>
          </>
      </Box>
    </Container>
  );
};

export default Student;