import React,{useState} from 'react';
import {TextField  } from '@mui/material';
import DOMPurify from 'dompurify';


const MultipleChoiceQuestion = React.memo(({
    question, options, selectedValue = '',  onValueChange = () => {}, questionNumber, isMCQuestion, onBlur, readOnly,markedForReview, onMarkForReview,crossedOut=[],toggleCrossedOut,toggleTrueCrossedOut,crossIcon,toggleCrossIcon

}) => {
  const handleChange = (event,index) => {
    onValueChange(event);
    toggleTrueCrossedOut(index); // Toggle the crossed-out state for this index

  };
  const processHtmlContent = (html) => {
    return html.replace(/<p>/gi, '<span>').replace(/<\/p>/gi, '</span>');
  };
  return (
    <div className="page-content">
      <div className="question-title">
      <div className="question-index">{questionNumber}</div>
      <div onClick={onMarkForReview} className={`bookmark ${markedForReview ? 'filled' : ''}`}>
            Mark for Review
        </div>
             {isMCQuestion ? (<div onClick={toggleCrossIcon} className={`crossing-options ${crossIcon ? 'filled' : ''}`}></div>):(<></>)}
    </div>
      
    <div className="q-dash"></div>
    <div className="question-content" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(processHtmlContent(question)) }}/>
          {isMCQuestion ? (
        <div className="answer-zone">
          {options.map((option, index) => (
            <div className="option" key={index}>
              <input type="radio" name="quiz-options" id={`option-${index}`} value={String(index)} checked={selectedValue === String(index)}  onChange={(event) => handleChange(event, index)} disabled={readOnly} />
              <label className={`select-zone ${crossedOut[index]&&crossIcon ? 'crossed':''} `} htmlFor={`option-${index}`}>
              {crossIcon&&crossedOut[index] && <hr className="cross-line" />}

                <div className="option-label">{String.fromCharCode(65 + index)}</div>
                <span className="option-content">
                  <div className="wrapper">
                  <div className="display" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(processHtmlContent(option)) }} />
                  </div>
                </span>
              </label>
              
              <div className="crossing-zone" onClick={() => toggleCrossedOut(index)} style={{display: crossIcon ? 'block' : 'none'}}>
              {crossedOut[index] ? (
    <span className="undo-cross">Undo</span>

) : (
    <>
    <div className="cross-label">{String.fromCharCode(65 + index)}</div>
    <hr className="cross-btn-line" />
</>
)}
        </div>

            </div>
          ))}
        </div>
      ) : (
        <TextField
        variant="outlined"
        value={selectedValue}
        onChange={onValueChange}
        onBlur={onBlur}
        style={{ marginTop: '10px', width: '100px' }} // Set the width as desired
        autoComplete="off"
      />
      
      )}
    </div>
  );
});

export default MultipleChoiceQuestion;