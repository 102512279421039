import { Typography, Box, useTheme,Link } from "@mui/material";
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';


import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";



const Header = ({ title, subtitle, subtitle2 = "", page, isLink = false }) => {
  const theme = useTheme();

  // Function to determine which icon to use based on the page
  const getIcon = () => {
    switch (page) {
      case 'manageExams':
        return <LibraryBooksOutlinedIcon sx={{ fontSize: 40, color: theme.palette.secondary.main }} />;
      case 'myExams':
        return <SchoolOutlinedIcon sx={{ fontSize: 40, color: theme.palette.secondary.main }} />;
      case 'dashboard':
        return <HomeOutlinedIcon sx={{ fontSize: 40, color: theme.palette.secondary.main }} />;
      case 'manageTeam':
        return <PeopleOutlinedIcon sx={{ fontSize: 40, color: theme.palette.secondary.main }} />;
      case 'scheduleList':
        return <MenuOutlinedIcon sx={{ fontSize: 40, color: theme.palette.secondary.main }} />;
      default:
        return <SchoolOutlinedIcon sx={{ fontSize: 40, color: theme.palette.secondary.main }} />; // Default icon
    }
  };

  return (
    <Box
      mb="30px"
      sx={{
        p: 3,
        bgcolor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderRadius: theme.shape.borderRadius,
        boxShadow: 1
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        {getIcon()}
        <Typography
          variant="h4"
          component="h1"
          fontWeight="bold"
          sx={{ flexGrow: 1 }}
        >
          {title}
        </Typography>
      </Box>

      {isLink ? (
        <Typography variant="subtitle1" fontStyle="italic">
          <Link href="https://status.lightentest.com" underline="hover" sx={{ color: theme.palette.info.main }}>
            {subtitle}
          </Link>
        </Typography>
      ) : (
        <Typography variant="subtitle1" fontStyle="italic">
          {subtitle}
        </Typography>
      )}

      {subtitle2 && (
        <Typography variant="subtitle1" fontStyle="italic" sx={{ mt: -0.5 }}>
          {subtitle2}
        </Typography>
      )}
    </Box>
  );
};

export default Header;

