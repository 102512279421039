import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { checkAuthenticated, load_user } from '../actions/auth';
import LoadingOverlay from '../components/LoadingOverlay';
import Error from '../scenes/error/Error';  // Import your error page
import './Layout.css';

const Layout = ({ checkAuthenticated, load_user, children }) => {
    const [isCheckingAuthStatus, setIsCheckingAuthStatus] = useState(true);
    const [error, setError] = useState(null);  // Add state for error handling

    useEffect(() => {
        const checkAuthStatus = async () => {
            try {
                await checkAuthenticated();
                await load_user();
            } catch (err) {
                console.error("Error during authentication check:", err);
                setError("An error occurred while checking authentication. Please try again later.");
            } finally {
                setIsCheckingAuthStatus(false);  // Always stop loading
            }
        };

        checkAuthStatus();
    }, []);

    if (isCheckingAuthStatus) {
        return (
            <LoadingOverlay />
        ); // Display your loading indicator
    }

    if (error) {
        return (
            <Error message={error} />  // Render the custom error page with the message
        );
    }

    return (
        <div>
            {children}
        </div>
    );
};

export default connect(null, { checkAuthenticated, load_user })(Layout);
