import React, { useState } from 'react';
import { Card, CardContent, Typography, Button, Grid, CardHeader,IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, Alert, Divider, Chip, Stack,Link as MuiLink  } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { deleteSchedule } from '../../actions/auth';
// import EventIcon from '@mui/icons-material/Event'; // Example icon for dates

const ScheduleCard = ({ exam, onDeleted }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const formatDateTime = (date) => {
        return new Date(date).toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            // year: 'numeric',
            hour12: true,
        });
    };
    const handleRowClick = (studentId) => {
        // console.log('history')
      navigate(`/students/${studentId}/exams`,{ state: { schedule: 'schedule' } });
  };
    const openDeleteConfirmDialog = () => {
        setIsDeleteConfirmOpen(true);
    };

    const closeDeleteConfirmDialog = () => {
        setIsDeleteConfirmOpen(false);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleDeleteSchedule = (scheduleId) => {
        dispatch(deleteSchedule(scheduleId))
            .then(() => {
                setSnackbarMessage('Schedule deleted successfully');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                setTimeout(() => {
                    onDeleted(); // Call the passed callback to refresh the list
                }, 500); // Adjust time as needed

            })
            .catch((err) => {
                setSnackbarMessage('Failed to delete the schedule, no permission');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            });
        setIsDeleteConfirmOpen(false);
    };

    const handleViewDetails = () => {
        navigate(`/schedule-details/${exam.id}`);
    };

    const duration = exam.schedule_type !== 'Full' ? exam.duration / 2 : exam.duration;

    return (
        <>
            <Grid item xs={12} sm={6} md={4}>
                <Card sx={{ maxWidth: 345, width: '100%' ,'&:hover button': {
    opacity: 1 // Make button visible when the card is hovered
  }}}>
                <CardHeader
      sx={{
        paddingBottom: '0px',
        '.MuiCardHeader-title': {
          fontWeight: 'bold', // makes title more prominent
          color: '#333' // darker color for better readability
        },
        '.MuiCardHeader-subheader': {
          margin: '8px 0 0', // adjust subheader margin for better visual alignment
        },
        '.MuiChip-root': {
          border: 'none', // removes the border from the Chip for a cleaner look
        }
      }}
      action={
        <IconButton onClick={openDeleteConfirmDialog} aria-label="delete" size="large" color="error" sx={{
             opacity: 0,
            transition: 'opacity 0.3s ease-in-out',
'&:hover': {
    backgroundColor: 'rgba(255, 0, 0, 0.1)',
  },
  '&:focus': {
    outline: 'none',
  },
  }}>
          <DeleteOutlinedIcon />
        </IconButton>
      }
      title={`${exam.exam_name} - ${exam.schedule_type}`}
      subheader={
        <Chip 
          icon={<EventOutlinedIcon />}
          label={`${formatDateTime(exam.start_time)} - ${formatDateTime(exam.end_time)}`}
          color="info"
          variant="outlined"
          sx={{
            fontSize: '0.875rem', // smaller font size for the Chip label
            fontWeight: 'medium' // medium weight for the Chip label
          }}
        />
      }
    />
                    <CardContent>

                    <Divider sx={{ mt: 0, mb: 2 }} />

                        <Typography color="textSecondary" gutterBottom>
            Exam taker: {exam.students_for_read.map((student, index, array) => (
                <span key={student.id}>
                    <MuiLink component="button" onClick={() => handleRowClick(student.id)} color="primary">
                        {student.name}
                    </MuiLink>
                    {index < array.length - 1 ? ', ' : ''}
                </span>
            ))}
        </Typography>
        <Typography color="textSecondary" gutterBottom>
        Duration: {`${duration} minutes`}
                        </Typography>
                        <Typography color="textSecondary" gutterBottom>
                           Auto transaction: {exam?.time_limit ? 'True' : 'False'}
                        </Typography>
                        <Typography color="textSecondary" gutterBottom>
                            Created on: {formatDateTime(exam?.created_at)}
                        </Typography>
                        
                        <Stack spacing={2} direction="column" mt={2}>
                        <Button variant="contained" color="primary" onClick={handleViewDetails}>
                            View Details
                        </Button>
                        </Stack>

                        
                    </CardContent>
                    
 
                </Card>
            </Grid>
            <Dialog
                open={isDeleteConfirmOpen}
                onClose={closeDeleteConfirmDialog}
            >
                <DialogTitle>{"Confirm Delete"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this schedule? This action will also erase all associated student entries and their exam records.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteConfirmDialog}>Cancel</Button>
                    <Button onClick={() => handleDeleteSchedule(exam.id)} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default ScheduleCard;
