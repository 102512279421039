import React from 'react';
import { Box, Typography, useTheme } from "@mui/material";

function Error() {
    const theme = useTheme();

    // Determine button color based on theme mode
    

    return (
        <Box 
            id="notfound" 
            style={{ 
                backgroundColor: theme.palette.background.default, 
                color: theme.palette.text.primary,
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                justifyContent: 'center', 
                height: '100vh' 
            }}
        >
            <Typography variant="h3" component="h3" gutterBottom>
                Oops! Something went wrong on our end. Please check back soon.
            </Typography>
            
        </Box>
    );
}

export default Error;
